import * as React from "react";
import * as THREE from "three";
import 'three/examples/js/loaders/GLTFLoader.js';

export interface ChooseCharacterProps {
    camera: THREE.Camera,
    renderer: THREE.Renderer,
}

export class ChooseCharacter extends React.Component<ChooseCharacterProps, {}> {
    mixer: THREE.AnimationMixer
    scene
    clock
    model

    constructor(props: ChooseCharacterProps) {
        super(props);
        // Don't call this.setState() here!
        this.state = { selected: 0 };

        this.init(props.camera)
    }

    init(camera: THREE.Camera) {
        camera.translateY(20)
        let scene = this.scene = new THREE.Scene();

        var ambientLight = new THREE.AmbientLight(0xa8a8b8);
        scene.add(ambientLight);

        var pointLight = new THREE.PointLight(0xffffff, 1, 100);
        pointLight.position.set(-5, 1, 5);
        scene.add(pointLight);

        var pointLight = new THREE.PointLight(0xffffff, 1, 100);
        pointLight.position.set(-5, 1, 5);
        scene.add(pointLight);

        var pointLight = new THREE.PointLight(0xffffff, 1, 100);
        pointLight.position.set(5, -10, 5);
        scene.add(pointLight);

        var loader = new window['THR'+'EE'].GLTFLoader();

        loader.load( 'dist/models/german-shepherd/scene.gltf', ( gltf:any ) => {
            let model: THREE.Mesh = this.model = gltf.scene;

            model.rotateY(Math.PI)
            scene.add( gltf.scene );

            this.mixer = new THREE.AnimationMixer(model);
            let i = 0;
            this.mixer.clipAction(gltf.animations[i]).setLoop(THREE.LoopRepeat, 1).play();
            this.mixer.addEventListener('finished', (event) => {
                i = (i + 1) % gltf.animations.length
                this.mixer.clipAction(gltf.animations[i]).setLoop(THREE.LoopRepeat, 1).play();
            })

            this.clock = new THREE.Clock();
        }, undefined, function ( error:any ) {
            console.error( error );
        } );
    }

    draw() {
        requestAnimationFrame(() => this.draw());
        if(this.clock) {
            var delta = this.clock.getDelta();
            this.model.rotateY(delta)
            if (this.mixer != null) {
                this.mixer.update(delta);
            };
            this.props.renderer.render(this.scene, this.props.camera)
        }
    }

    render() {
        this.draw();
        return ''
    }
}