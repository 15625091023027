import * as React from "react";
import { ChooseCharacter } from "../choose-character/choose-character";
import { Scene, WebGLRenderer, PerspectiveCamera, LineBasicMaterial, Vector3, BufferGeometry, Line } from "three";

export interface WebGlProps { id: string }

let renderer = new WebGLRenderer();
renderer.setSize( window.innerWidth, window.innerHeight );
document.body.appendChild( renderer.domElement );

let camera = new PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 500 );
camera.position.set( 0, 0, 100 );
camera.lookAt( 0, 0, 0 );

let state = 'choose-character'

export class WebGl extends React.Component<WebGlProps, {}> {
    render() {
        console.log(state)
        return state == 'choose-character' ? <ChooseCharacter camera={camera} renderer={renderer}/> : ''
    }
}